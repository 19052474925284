.form-control,
.input-group-text,
.custom-select,
.custom-file-label {
  @include form-control;
  &:focus {
    @include form-control-focus;
  }
}

.input-group-text,
select.form-control:not([size]):not([multiple]),
.form-control:not(.form-control-sm):not(.form-control-lg) {
  font-size: 14px;
  padding: 10px 15px;
  height: 42px;
}

textarea.form-control {
  height: 64px;
}

.custom-control {
  line-height: 1.6rem;
}

.custom-file,
.custom-file-label,
.custom-select,
.custom-file-label:after,
.form-control[type="color"],
select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 6px);
}

.form-control.creditcard {
  background-position: 98%;
  background-repeat: no-repeat;
  background-size: 40px;
  padding-right: 60px;
  &.paypal {
    @include paypal;
  }
  &.visa {
    @include visa;
  }
  &.americanexpress {
    @include americanexpress;
  }
  &.dinersclub {
    @include dinersclub;
  }
  &.discover {
    @include discover;
  }
  &.jcb {
    @include jcb;
  }
  &.mastercard {
    @include mastercard;
  }
}

.form-group {
  margin-bottom: 25px;
  .control-label,
  > label {
    font-weight: 600;
    color: color(fontdark);
    font-size: 12px;
    letter-spacing: .5px;
  }
  &.floating-addon {
    position: relative;
    .input-group-prepend {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 5;
    }
    &:not(.floating-addon-not-append) {
      .input-group-append {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        left: initial;
        right: 0;
      }
    }
    .input-group-prepend .input-group-text, .input-group-append .input-group-text {
      border-color: transparent;
      background-color: transparent;
      font-size: 20px;
    }
    .form-control {
      border-radius: 3px;
      padding-left: 40px;
      + .form-control {
        border-radius: 0 3px 3px 0;
        padding-left: 15px;
      }
    }
  }
}

.input-group-append [class*="btn-outline-"] {
  background-color: color_lighten(primary, 32.4%);
}

.form-text {
  font-size: 12px;
  line-height: 22px;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before,
.custom-control-input:checked~.custom-control-label::before {
  background-color: color(primary) !important;
}

.custom-checkbox-table {
}

.custom-file-label {
  line-height: 2.2;
  &:after {
    height: calc(2.25rem + 4px);
    line-height: 2.2;
    border-color: transparent;
  }
  &:focus, &:active {
    box-shadow: none;
    outline: none;
  }
}

.custom-file-input {
  &:focus {
    + .custom-file-label {
      box-shadow: none;
      border-color: color(primary);
      &:after {
        border-color: transparent;
      }
    }
  }
}

// Thank you Tabler
.selectgroup {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.selectgroup-item {
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  + .selectgroup-item {
    margin-left: -1px;
  }
  &:not(:first-child) .selectgroup-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &:not(:last-child) .selectgroup-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.selectgroup-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

.selectgroup-button {
  @include form-control;
  border-width: 1px;
  border-style: solid;
  display: block;
  text-align: center;
  padding: 0 1rem;
  height: 35px;
  position: relative;
  cursor: pointer;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 13px;
  min-width: 2.375rem;
  line-height: 36px;
}

.selectgroup-button-icon {
  padding-left: .5rem;
  padding-right: .5rem;
  i {
    font-size: 14px;
  }
}

.selectgroup-input {
  &:checked + .selectgroup-button {
    background-color: color(primary);
    color: #fff;
    z-index: 1;
  }
}

.selectgroup-pills {
  display: block;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: start;
  align-items: flex-start;
  .selectgroup-item {
    margin-right: .5rem;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  .selectgroup-button {
    border-radius: 50px !important;
  }
}

.custom-switch {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
}

.custom-switch-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-switches-stacked {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  .custom-switch {
    margin-bottom: .5rem;
  }
}

.custom-switch-indicator {
  display: inline-block;
  height: 1.25rem;
  width: 2.25rem;
  background: #e9ecef;
  border-radius: 50px;
  position: relative;
  vertical-align: bottom;
  border: 1px solid rgba(0, 40, 100, 0.12);
  transition: .3s border-color, .3s background-color;
  &:before {
    content: '';
    position: absolute;
    height: calc(1.25rem - 4px);
    width: calc(1.25rem - 4px);
    top: 1px;
    left: 1px;
    background: #fff;
    border-radius: 50%;
    transition: .3s left;
  }
}

.custom-switch-input {
  &:checked ~ .custom-switch-indicator {
    background: color(primary);
    &:before {
      left: calc(1rem + 1px);
    }
  }
  &:focus ~ .custom-switch-indicator {
    border-color: color(primary);
  }
}

.custom-switch-description {
  margin-left: .5rem;
  color: #6e7687;
  transition: .3s color;
}

.custom-switch-input:checked ~ .custom-switch-description {
  color: #495057;
}

.imagecheck {
  margin: 0;
  position: relative;
  cursor: pointer;
}

.imagecheck-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.imagecheck-figure {
  @include form-control;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  margin: 0;
  position: relative;
}

.imagecheck-input {
  &:focus ~ .imagecheck-figure {
    border-color: color(primary);
  }
  &:checked ~ .imagecheck-figure {
    border-color: rgba(0, 40, 100, 0.24);
  }
}

.imagecheck-figure:before {
  content: '';
  position: absolute;
  top: .25rem;
  left: .25rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: color(primary) url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center (center / 50%) 50%;
  color: #fff;
  z-index: 1;
  border-radius: 3px;
  opacity: 0;
  transition: .3s opacity;
}

.imagecheck-input:checked ~ .imagecheck-figure:before {
  opacity: 1;
}

.imagecheck-image {
  max-width: 100%;
  opacity: .64;
  transition: .3s opacity;
  &:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  &:last-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
}

.imagecheck:hover .imagecheck-image {
  opacity: 1;
}

.imagecheck-input {
  &:focus ~ .imagecheck-figure .imagecheck-image, &:checked ~ .imagecheck-figure .imagecheck-image {
    opacity: 1;
  }
}

.imagecheck-caption {
  text-align: center;
  padding: .25rem .25rem;
  color: #9aa0ac;
  font-size: 0.875rem;
  transition: .3s color;
}

.imagecheck:hover .imagecheck-caption {
  color: #495057;
}

.imagecheck-input {
  &:focus ~ .imagecheck-figure .imagecheck-caption, &:checked ~ .imagecheck-figure .imagecheck-caption {
    color: #495057;
  }
}

.colorinput {
  margin: 0;
  position: relative;
  cursor: pointer;
}

.colorinput-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.colorinput-color {
  @include form-control;
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 3px;
  color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  &:before {
    content: '';
    opacity: 0;
    position: absolute;
    top: .25rem;
    left: .25rem;
    height: 1.25rem;
    width: 1.25rem;
    transition: .3s opacity;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center (center / 50%) 50%;
  }
}

.colorinput-input {
  &:checked ~ .colorinput-color:before {
    opacity: 1;
  }
}
