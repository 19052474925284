// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Font awesome
@import "~font-awesome/scss/font-awesome.scss";

/* 1. Variable */
@import "_variable";

/* 2. Mixin */
@import "_mixin";

@import "override/_misc";

@import "override/_form";

@import "override/_card";

@import "override/_button";


.active .bs-stepper-circle {
    background-color: color(theme-primary) !important;
}

// Theme
body {
    font-family: "Montserrat", sans-serif;
    color: #252a2a;
    background-color: #FFFFFF !important;
    font-size: 1em;
}

a {
    color: #444444;
    text-decoration: none;
}

a:hover {
    color: #a70c35;
    text-decoration: none;
}

.content {
    padding-top: 140px;
}

/*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #bc0d3d;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    transition: all 0.4s;
}

.back-to-top i {
    font-size: 24px;
    color: #fff;
    line-height: 0;
}

.back-to-top:hover {
    background: #999999;
    color: #fff;
}

.back-to-top.active {
    visibility: visible;
    opacity: 1;
}

/*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
@media screen and (max-width: 768px) {
    [data-aos-delay] {
        transition-delay: 0 !important;
    }
}

/*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
#header {
    transition: all 0.5s;
    z-index: 997;
    transition: all 0.5s;
    padding: 15px 0;
    background: rgba(255, 255, 255, 0.95);
}

#header.header-transparent {
    background: transparent;
}

#header.header-scrolled {
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#header .logo h1 {
    font-size: 30px;
    margin: 0;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 2px;
}

#header .logo img {
    margin: 0;
    max-height: 40px;
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/**
  * Desktop Navigation 
  */
.navbar {
    padding: 0;
}

.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
}

.navbar li {
    position: relative;
}

.navbar a,
.navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-size: 15px;
    color: #1a1a1a;
    white-space: nowrap;
    transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
    line-height: 0;
    margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
    color: #8f0a2d;
}

/**
  * Mobile Navigation 
  */
.mobile-nav-toggle {
    color: #47536e;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
    color: #fff;
}

@media (max-width: 991px) {
    .mobile-nav-toggle {
        display: block;
    }

    .navbar ul {
        display: none;
    }
}

.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(51, 60, 79, 0.9);
    transition: 0.3s;
    z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
}

.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 6px;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #47536e;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
    color: #a70c35;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
    margin: 15px;
}

.navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
    min-width: 200px;
}

.navbar-mobile .dropdown ul a {
    padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
    
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
    color: #a70c35;
}

.navbar-mobile .dropdown>.dropdown-active {
    display: block;
}

/*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
#hero {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 140px 0 100px 0;
}

#hero::before {
    content: "";
    position: absolute;
    right: -100%;
    top: 20%;
    width: 250%;
    height: 200%;
    z-index: -1;
    background-color: #e6e6e6;
    transform: skewY(135deg);
}

#hero h1 {
    margin: 0 0 10px 0;
    font-size: 48px;
    font-weight: 500;
    line-height: 56px;
}

#hero h2 {
    margin-bottom: 50px;
    font-size: 20px;
}

@media (max-width: 991px) {
    #hero {
        text-align: center;
    }

    #hero .download-btn+.download-btn {
        margin: 0 10px;
    }

    #hero .hero-img {
        text-align: center;
    }

    #hero .hero-img img {
        width: 60%;
    }
}

@media (max-width: 768px) {
    #hero h1 {
        font-size: 28px;
        line-height: 36px;
    }

    #hero h2 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 30px;
    }

    #hero .hero-img img {
        width: 70%;
    }
}

@media (max-width: 575px) {
    #hero .hero-img img {
        width: 80%;
    }
}

/*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
section {
    padding: 60px 0;
    overflow: hidden;
}

.section-bg {
    background-color: #f2f2f2;
}

.section-title {
    text-align: center;
    padding-bottom: 30px;
}

.section-title h2 {
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #444444;
}

.section-title p {
    margin-bottom: 0;
}

/*--------------------------------------------------------------
  # App Features
  --------------------------------------------------------------*/
.features .content {
    padding: 30px 0;
}

.features .content .icon-box {
    margin-top: 25px;
}

.features .content .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 5px 0 10px 60px;
}

.features .content .icon-box i {
    font-size: 48px;
    float: left;
    color: #a70c35;
}

.features .content .icon-box p {
    font-size: 15px;
    margin-left: 60px;
}

@media (max-width: 991px) {
    .features .image {
        text-align: center;
    }

    .features .image img {
        max-width: 80%;
    }
}

@media (max-width: 667px) {
    .features .image img {
        max-width: 100%;
    }
}

/*--------------------------------------------------------------
  # Frequently Asked Questions
  --------------------------------------------------------------*/
.faq .accordion-list {
    padding: 0 100px;
}

.faq .accordion-list ul {
    padding: 0;
    list-style: none;
}

.faq .accordion-list li+li {
    margin-top: 15px;
}

.faq .accordion-list li {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    position: relative;
}

.faq .accordion-list a {
    display: block;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding: 0 30px;
    outline: none;
    cursor: pointer;
}

.faq .accordion-list .icon-help {
    font-size: 24px;
    position: absolute;
    right: 0;
    left: 20px;
    color: #b1c0df;
}

.faq .accordion-list .icon-show,
.faq .accordion-list .icon-close {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
}

.faq .accordion-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
}

.faq .accordion-list .icon-show {
    display: none;
}

.faq .accordion-list a.collapsed {
    color: #343a40;
}

.faq .accordion-list a.collapsed:hover {
    color: #a70c35;
}

.faq .accordion-list a.collapsed .icon-show {
    display: inline-block;
}

.faq .accordion-list a.collapsed .icon-close {
    display: none;
}

@media (max-width: 1200px) {
    .faq .accordion-list {
        padding: 0;
    }
}

/*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
.contact .info {
    padding: 20px 40px;
    text-align: center;
}

.contact .info i {
    font-size: 48px;
    color: #999999;
    margin-bottom: 15px;
}

.contact .info h4 {
    padding: 0;
    margin: 0 0 10px 0;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
}

.contact .info p {
    font-size: 15px;
}

/*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
#footer {
    background-color: #393434;
    padding: 0 0 30px 0;
    color: #FFFFFF;
    font-size: 14px;
}

#footer .footer-top {
    padding: 60px 0 30px 0;
    background: #393434;
}

#footer .footer-top .footer-contact {
    margin-bottom: 30px;
}

#footer .footer-top .footer-contact h4 {
    font-size: 22px;
    margin: 0 0 30px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
    color: #FFFFFF;
}

#footer .footer-top .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    color: #FFFFFF;
}

#footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    position: relative;
    padding-bottom: 12px;
}

#footer .footer-top .footer-links {
    margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 1;
}

#footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}

#footer .footer-top .footer-links ul a {
    color: #FFFFFF;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
    color: #e6e6e6;
}

#footer .copyright {
    text-align: center;
    float: left;
    color: #FFFFFF;
}

@media (max-width: 768px) {
    .bs-stepper-header {
        overflow-x: auto;
    }

    #footer .footer-top .footer-contact {
        display: flex;
        justify-content: center;
    }

    #footer .copyright,
    #footer .credits {
        float: none;
        text-align: center;
        padding: 5px 0;
    }
}
